import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import ArrowBack from "../assets/ArrowBack";
import ArrowForward from "../assets/ArrowForward";
import ArrowForwardSpecial from "../assets/ArrowForwardSpecial.jsx";

import CoverPage from "./CoverPage";
import Page1 from "./Page1";
import Page2 from "./Page2";
import BackCover from "./BackCover.js";

const Book = () => {
  const totalPages = 4;
  const [previousPage, setPreviousPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    setPreviousPage(currentPage);
    setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
  };

  const prevPage = () => {
    setPreviousPage(currentPage);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentPage < totalPages) {
        nextPage();
      }
    },
    onSwipedRight: () => {
      if (currentPage > 1) {
        prevPage();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && currentPage !== 1) {
        setPreviousPage(currentPage);
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      } else if (event.key === "ArrowRight" && currentPage < totalPages) {
        setPreviousPage(currentPage);
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentPage, totalPages]);

  useEffect(() => {
    console.log("Current page changed. Scrolling to top.");
    window.scrollTo(0, 0);
  }, [currentPage]);

  const bookContainerStyle = {
    bgcolor: "#1A1A1A",
    // overflow: "hidden",
    //overflowY: "auto",
  };

  const prevButtonStyle = {
    position: "absolute",
    top: "47.5%",
    left: 0,
    display: "flex",
    zIndex: 1000,
  };

  const forwardButtonStyle = {
    position: "absolute",
    top: "47.5%",
    right: 0,
    display: "flex",
    zIndex: 1000,
  };

  const RenderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <CoverPage
            checked={currentPage === 1}
            isReverse={previousPage > currentPage}
          />
        );
      case 2:
        return (
          <Page1
            checked={currentPage === 2}
            isReverse={previousPage > currentPage}
          />
        );
      case 3:
        return (
          <Page2
            checked={currentPage === 3}
            isReverse={previousPage > currentPage}
          />
        );
      case 4:
        return (
          <BackCover
            checked={currentPage === 4}
            isReverse={previousPage > currentPage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container sx={bookContainerStyle} {...handlers}>
      <RenderPage />
      {currentPage > 1 && (
        <IconButton
          sx={prevButtonStyle}
          onClick={prevPage}
          aria-label="Previous Page"
        >
          <ArrowBack />
        </IconButton>
      )}
      <IconButton
        sx={forwardButtonStyle}
        onClick={nextPage}
        aria-label="Next Page"
      >
        {currentPage === totalPages || currentPage === 1 ? (
          <ArrowForwardSpecial />
        ) : (
          <ArrowForward />
        )}
      </IconButton>
    </Grid>
  );
};

export default Book;
