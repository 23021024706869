import React from "react";
import { Grid, Slide, Typography, Box } from "@mui/material";
import Cover from "../assets/cover.mp4";
import Logo from "../assets/logo.webp";
import Poster from "../assets/poster.webp";
import { HeaderStyle } from "../fonts";

const CoverPage = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "left" : "right"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={8} lg={6}>
            <Grid
              container
              direction={"column"}
              sx={{ height: "100%", bgcolor: "#000" }}
            >
              <Grid
                item
                xs={7}
                sx={{
                  borderBottom: "1vh solid #fff",
                }}
              >
                <video
                  playsInline
                  controls
                  preload="auto"
                  poster={Poster}
                  style={{ height: "65vh", width: "100%", objectFit: "cover" }}
                >
                  <source src={Cover} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid
                item
                xs={3.9}
                sx={{
                  paddingLeft: "3vh",
                }}
              >
                <Grid
                  container
                  direction={"row"}
                  sx={{ height: "100%", paddingTop: "0vh" }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      padding: "0vh",
                      backgroundImage: `url('${Logo}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      "@media (orientation: portrait)": {
                        backgroundSize: { xs: "120%" },
                      },
                      "@media (orientation: landscape)": {
                        backgroundSize: { xs: "90%", md: "95%", lg: "110%" },
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={8}
                    sx={{
                      paddingTop: "1vh",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        ...HeaderStyle,

                        "@media (orientation: landscape)": {
                          fontSize: { xs: "3vh", lg: "4vh" },
                          lineHeight: { xs: "2.5vw", md: "2vw", lg: "2.1vw" },
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "3vh" },
                          lineHeight: { xs: "3.3vh", md: "3.5vh" },
                          textAlign: { xs: "center" },

                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      Meet our Co-Founder Noah Firestone
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default CoverPage;
