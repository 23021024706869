import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";
import SwipeableTextMobileStepper from "./MobileStepper";
import EmbeddedStreetView from "./GoogleMaps";
import { SecondaryStyle, HeaderStyle } from "../fonts";

import TopLeft from "../assets/Page2LD.webp";
import BottomGif from "../assets/Page2LC.gif";
import TopRight from "../assets/Page2RD.webp";
import TopGif from "../assets/Page2RA.gif";
import CarouselImage1 from "../assets/Page2Slide1.webp";
import CarouselImage2 from "../assets/Page2Slide2.webp";
import CarouselImage3 from "../assets/Page2Slide3.webp";

const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3];
const Page2 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          overflow: { xs: "auto", sm: "hidden" },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      backgroundImage: `url('${TopLeft}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderBottom: ".5vh solid #000",
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  >
                    <SwipeableTextMobileStepper images={carouselImages} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BottomGif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: "4px solid #000",
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: { sm: "4px solid #000" },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw" },
                          lineHeight: { xs: "3.3vh", md: "3.1vh" },
                          textAlign: { xs: "center" },
                          paddingTop: { xs: "1vh", md: "1vh" },
                          paddingBottom: { xs: "2vh", sm: "0vh" },
                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      Outside of Work
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1vw", md: "1vw", lg: "1vw" },
                          lineHeight: { xs: "1.1vw", md: "1.1vw", lg: "1.1vw" },
                          paddingTop: "0vw",
                          paddingRight: "1vw",
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.7vh",
                            sm: "1vh",
                          },
                          lineHeight: {
                            xs: "2vh",
                            sm: "1.65vh",
                            md: "1.5vh",
                            lg: "1.8vh",
                          },
                          paddingTop: "0vh",
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", lg: "3vh" },
                        },
                      }}
                    >
                      Family has always been a big priority in my life. I
                      absolutely love dogs and clearly food/cooking is one of my
                      deepest passions. <br />
                      <br />I try to make time to travel often and my favourite
                      way do that is by backpacking through foreign countries
                      and exploring unfamiliar foods and cultures.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderBottom: ".5vh solid #000",
                      backgroundImage: `url('${TopRight}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },

                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${TopGif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: ".5vh solid #000",
                      borderRight: {
                        xs: "none",
                        sm: "1vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  >
                    <EmbeddedStreetView />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: ".5vh solid #000",
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw" },
                          lineHeight: { xs: "3.3vh", md: "3.1vh" },
                          textAlign: { xs: "center" },
                          paddingTop: { xs: "2vh", md: "1vh" },
                          paddingLeft: { xs: "1vh", lg: "3vh" },
                          paddingBottom: { xs: "2vh", sm: "0vh" },
                        },
                      }}
                    >
                      Growing up
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1vw", md: "1vw", lg: "1vw" },
                          lineHeight: { xs: "1.1vw", md: "1.1vw", lg: "1.1vw" },
                          paddingTop: "0vw",
                          paddingRight: "1vw",
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.7vh",
                            sm: "1vh",
                          },
                          lineHeight: {
                            xs: "2vh",
                            sm: "1.65vh",
                            md: "1.5vh",
                            lg: "1.8vh",
                          },
                          paddingTop: "0vh",
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", lg: "3vh" },
                        },
                      }}
                    >
                      This is my childhood house in Thornhill, Ontario. Just
                      north of Toronto, it is the suburb my parents decided to
                      settle down in to raise a family. Picturesque lawns, tons
                      of parks and none of the hustle and bustle the city is
                      famous for.
                      <br />
                      <br /> As a child the city was always quite intimidating.
                      When the time for me to leave the nest eventually came, I
                      moved to London, Ontario to attend Ivey Business School at
                      Western University and by the time I graduated I could not
                      wait to move to Toronto. The city allowed me to chase the
                      opportunities that only a major metropolis can offer,
                      while still being near family.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page2;
