import React from "react";
import { Box, Grid, Slide, Typography, Link } from "@mui/material";

import { SecondaryStyle } from "../fonts";

import Poster from "../assets/poster.webp";
import Avatar from "../assets/Page1Profile.webp";
import Cover from "../assets/BackCover.mp4";
import Logo from "../assets/logo.webp";

const BackCover = (props) => {
  const { checked } = props;

  return (
    <Slide
      direction={"left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          height: "100vh",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={9} lg={6}>
            <Grid
              container
              direction={"column"}
              sx={{ height: "100%", bgcolor: "#000" }}
            >
              <Grid
                item
                xs={2.5}
                sx={{
                  backgroundImage: `url('${Logo}')`,
                  backgroundRepeat: "no-repeat",

                  backgroundSize: "40%",
                  "@media (orientation: portrait)": {
                    backgroundSize: "70%",
                  },
                  backgroundPosition: "center",
                }}
              />
              <Grid
                item
                xs={4.7}
                sx={{
                  paddingBottom: "1vh",
                  borderBottom: "1vh solid #fff",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <video
                  playsInline
                  controls
                  preload="auto"
                  poster={Poster}
                  style={{
                    width: "75%",
                    height: "36vh",
                    objectFit: "cover",
                  }}
                >
                  <source src={Cover} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    paddingTop: "1vh",
                    paddingBottom: "1vh",
                    height: "67%",
                    width: "75%",
                  }}
                >
                  <Grid
                    item
                    xs={3.5}
                    sm={2.3}
                    sx={{
                      backgroundPosition: "center",
                      backgroundImage: `url('${Avatar}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />

                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <Grid container direction={"column"}>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          paddingLeft: "3vh",
                          textAlign: "left",
                        }}
                      >
                        <Typography
                          sx={{
                            ...SecondaryStyle,
                            opacity: 0.9,

                            paddingBottom: "0vh",
                            "@media (orientation: landscape)": {
                              fontSize: {
                                xs: "2.7vh",
                                lg: "3vh",
                              },
                              lineHeight: {
                                xs: "2.5vh",
                              },
                            },
                            "@media (orientation: portrait)": {
                              fontSize: {
                                xs: "2.5vh",
                                md: "3vh",
                              },
                              lineHeight: { xs: "2vh", md: "2.7vh" },
                            },
                          }}
                        >
                          Noah Firestone
                          <br />
                        </Typography>

                        <Typography
                          sx={{
                            ...SecondaryStyle,
                            opacity: 0.9,

                            "@media (orientation: landscape)": {
                              fontSize: {
                                xs: "1.8vh",
                                lg: "2vh",
                              },
                              lineHeight: {
                                xs: "1.9vw",
                                md: "1.9vw",
                                lg: "1.3vw",
                              },
                            },
                            "@media (orientation: portrait)": {
                              fontSize: {
                                xs: "1.75vh",
                                md: "1.9vh",
                              },
                              lineHeight: { xs: "2vh", md: "2.7vh" },
                            },
                          }}
                        >
                          Co-Founder
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7.84}
                        sx={{
                          paddingLeft: "3vh",
                          textAlign: "left",
                          paddingBottom: "0vh",
                          paddingTop: "10vh",
                        }}
                      >
                        <Typography
                          sx={{
                            ...SecondaryStyle,
                            opacity: 0.9,
                            paddingBottom: "0vh",
                            "@media (orientation: landscape)": {
                              fontSize: {
                                xs: "1.8vh",
                                lg: "2vh",
                              },
                              lineHeight: {
                                xs: "1.3vw",
                              },
                            },
                            "@media (orientation: portrait)": {
                              fontSize: {
                                xs: "1.75vh",
                                md: "1.9vh",
                              },
                              lineHeight: { xs: "2vh", md: "2.7vh" },
                            },
                          }}
                        >
                          <Link
                            href="tel:+14165791727"
                            sx={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            416-579-1727 <br />
                          </Link>
                          <Link
                            href="mailto:noahfirestone@gmail.com"
                            sx={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            noahfirestone@gmail.com <br />
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={0.7}
                sx={{
                  borderTop: ".1vh solid #fff",
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    ...SecondaryStyle,
                    opacity: 0.9,
                    textAlign: "center",
                    paddingTop: "2.2vh",
                    "@media (orientation: landscape)": {
                      fontSize: {
                        xs: "1.5vh",
                        md: "1.6vh",
                        xl: "1.75 ",
                      },
                      lineHeight: { xs: "1vh", md: "1.7vh", lg: "1.7vh" },
                    },
                    "@media (orientation: portrait)": {
                      fontSize: {
                        xs: "1.3vh",
                        sm: "1.2vh",
                        lg: "2vh",
                      },
                      paddingTop: { xs: ".5vh", sm: "2vh" },
                      lineHeight: { xs: "1.1  vh", md: "1.7vh", lg: "1.7vh" },
                    },
                  }}
                >
                  Not intended to solicit Sellers or Buyers currently under
                  written contract with another Realtor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default BackCover;
