import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";
import SwipeableTextMobileStepper from "./MobileStepper";

import { SecondaryStyle, HeaderStyle } from "../fonts";

import Camera from "../assets/Page1RB&C.webp";
import BottomLeftImage from "../assets/Page1Profile.webp";
import Gif from "../assets/Page1Gif.gif";
import CarouselImage1 from "../assets/Page1Slideshow1.webp";
import CarouselImage2 from "../assets/Page1Slideshow2.webp";
import CarouselImage3 from "../assets/Page1Slideshow3.webp";
import CarouselImage4 from "../assets/Page1Slideshow4.webp";

const carouselImages = [
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
];
const Page1 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          overflow: { xs: "auto", sm: "hidden" },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: landscape)": {
                          fontSize: "1.9vw",
                          lineHeight: {
                            xs: "2.5vw",
                            md: "2.3vw",
                            lg: "2.1vw",
                          },
                          paddingTop: {
                            xs: "1vh",
                            md: "1vh",
                            lg: "3vh",
                          },
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw", md: "3vw" },
                          lineHeight: { xs: "3vh", md: "2.5vh" },
                          textAlign: { xs: "center", sm: "left" },
                          paddingBottom: { xs: "2vh", sm: "0vh" },
                          paddingTop: { xs: "1vh", sm: "1vh", md: ".5vh" },
                          paddingLeft: { xs: "1vh", sm: "3vh" },
                        },
                      }}
                    >
                      Hi I’m Noah
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1vw", md: "1vw", lg: "1vw" },
                          lineHeight: { xs: "1.1vw", md: "1.1vw", lg: "1.1vw" },
                          paddingTop: "0vw",
                          paddingRight: "1vw",
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.7vh",
                            sm: "1vh",
                          },
                          lineHeight: {
                            xs: "2vh",
                            sm: "1.65vh",
                            md: "1.5vh",
                            lg: "1.8vh",
                          },
                          paddingTop: {
                            xs: "0vh",
                            sm: "2.2vh",
                            md: "1.2vh",
                            lg: "0vh",
                          },
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", lg: "3vh" },
                        },
                      }}
                    >
                      As mentioned on the cover, I am Cofounder of StoryTime you
                      will be meeting with.
                      <br />
                      <br />I look forward to answering any questions you may
                      have about our strategy of interactive storytelling, as
                      well as sharing insights with you about how top performing
                      real estate agents are using our solution to overcome some
                      of the challenges they are facing in the industry.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1vw", md: "1vw", lg: "1vw" },
                          lineHeight: { xs: "1.1vw", md: "1.1vw", lg: "1.1vw" },
                          paddingTop: {
                            xs: "3.5vw",
                            md: "3.5vw",
                            lg: "6.5vh",
                            xl: "4vw",
                          },
                          paddingRight: "1vw",
                          paddingLeft: { xs: "2vh", sm: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.7vh",
                            sm: "1vh",
                          },
                          lineHeight: {
                            xs: "2vh",
                            sm: "1.65vh",
                            md: "1.5vh",
                            lg: "1.8vh",
                          },
                          paddingTop: { xs: "0vh", sm: "6vh", md: "4vh" },
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", lg: "3vh" },
                        },
                      }}
                    >
                      I've been working with Agents on this strategy for several
                      years now. In that time I’ve had the chance to work with
                      over 1000 top performing agents and brokers.
                      <br /> <br />
                      It's been a truly rewarding experience getting to know so
                      many entrepreneurs. As someone who has been an
                      entrepreneur myself for most of my life (and who's father
                      and both grandfathers were entrepreneurs) it has been
                      incredible getting to hear everyones story.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BottomLeftImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: "4px solid #000",
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${Gif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: "4px solid #000",
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderBottom: ".5vh solid #000",

                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },

                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  >
                    <SwipeableTextMobileStepper
                      images={carouselImages}
                    ></SwipeableTextMobileStepper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,

                        "@media (orientation: landscape)": {
                          fontSize: "1.9vw",
                          lineHeight: {
                            xs: "2.5vw",
                            md: "2.3vw",
                            lg: "2.1vw",
                          },
                          paddingTop: { xs: "1vh", lg: "3vh" },
                          paddingLeft: { xs: "2vh", md: "2vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw", md: "3vw" },
                          lineHeight: { xs: "3vh", md: "2.5vh" },
                          textAlign: { xs: "center" },
                          paddingBottom: { xs: "2vh", sm: "0vh" },
                          paddingTop: { xs: "1vh", sm: "0.1vh", md: ".5vh" },
                          paddingLeft: { xs: "1vh", lg: "vh" },
                        },
                      }}
                    >
                      Before StoryTime
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1vw", md: "1vw", lg: "1vw" },
                          lineHeight: {
                            xs: "2vh",
                            md: "2vh",
                            lg: "2.2vh",
                          },
                          paddingTop: "0vw",
                          paddingRight: "1vw",
                          paddingLeft: { xs: "2vh", md: "2vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.7vh",
                            sm: "1vh",
                          },
                          lineHeight: {
                            xs: "2vh",
                            sm: "1.6vh",
                            md: "1.5vh",
                            lg: "1.8vh",
                          },
                          paddingTop: { xs: "0vw", sm: "1vh", md: "1vh" },
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", sm: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      Previously, I spent time working at a real estate strategy
                      company where I was first introduced to the concept of
                      strategic interactive storytelling. While I loved the
                      company I worked for, I kept running into customers facing
                      a lot of the same challenges. Eventually I was inspired to
                      launch StoryTime to solve for those challenges. <br />
                      <br />
                      Before working in real estate, I had jobs in finance,
                      healthcare and television. I worked at both startups and
                      large Fortune 50 companies, helping business owners
                      overcome challenges by using technology effectively.
                      During lockdowns I took a break from the corporate world
                      and spent a year running a food business to pursue a dream
                      of cooking professionally. Currently, to scratch that
                      itch, I run my own private dinner catering business in my
                      spare time.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      backgroundImage: `url('${Camera}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  ></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page1;
