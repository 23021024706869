import React from "react";

const EmbeddedStreetView = () => {
  const location = "43.8045133,-79.4582682";

  const embedUrl = `https://www.google.com/maps/embed?pb=!4v1643604171442!6m8!1m7!1sCAoSLEFGMVFpcE0xMmRzVzI1QnA5Rk5SaEhXM1V5cDBVTU4wbUtaeFFVSWl2cGdK!2m2!1d${
    location.split(",")[0]
  }!2d${
    location.split(",")[1]
  }!3f235.7866858243289!4f2.8368719459990535!5f0.7820865974627469`;
  return (
    <iframe
      title="Embedded Street View"
      width="100%"
      height="100%"
      loading="lazy"
      allowFullScreen
      src={embedUrl}
    />
  );
};

export default EmbeddedStreetView;
